// Step 1: Import React
import React, { useState, useEffect } from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import DateSelect from "../../components/dateSelect/dateSelect";
import Report from "../../components/report/report";
import ProductBanner from "../../components/product_banners/product_banners";
import Banner from "../../imgs/bg_reportes.jpg";
import NewsLetter from "../../components/newsletter/newsletter";
import { navigate } from 'gatsby';
/*import "../pages.css";*/
import "./reporte.css";
import "../articles.css";
import {apiRequest} from "../../apiRquest/apiRequest";
import Loading from "../../components/loading/loading";

const pageTitle = 'Daily | Reporte Balanz';
const pageDesc = 'Te presentamos un resumen diario de los eventos más relevantes del mercado. Mantenete informado y potenciá tu capital.';


// Step 2: Define your component
const ReportPageDaily = () => {
  const [type, setType] = useState("daily");
  const [reportSlug, setReportSlug] = useState(null);
  const [reportsData, setReportsData] = useState([]);
  const [otherReports, setOtherReports] = useState([]);
  const [reportData, setReportData] = useState({});
  const [loadingReports, setLoadingReports] = useState(false);

  let options = [
    { name: "Daily", value: "daily", className: "report-option" },
    { name: "Weekly Fondos", value: "weekly-fondos",className: "report-option" },
    /*{ name: "Weekly Market", value: "weekly-market",className: "report-option" },
    { name: "Monthly", value: "monthly",className: "report-option" },*/
    { name: "Prensa", value: "prensa",className: "report-option" },
  ];

    useEffect(() => {
        const handleChange = () => {
            navigate(`/reportes/${ type }`);
        };

        if(type != "daily"){
            handleChange();
        }
    }, [type]);

    useEffect(() => {
        if (type != null ) {
            setLoadingReports(true);

            apiRequest
             //.get(`reports/reportes.json`)
            .get("/v1/reports/daily")
            .then((res) => {

                let dataSet = [];
                let fullData = res.data.data;
                let filteredData =  fullData.filter(element => element.type === type);
                // console.log('filteredData', filteredData.length)
                if (filteredData.length > 0){
                    filteredData.forEach((report) => {
                        dataSet.push({
                            date: new Date(report.date),
                            type: report.type,
                            link: report.link,
                            slug: report.slug,
                            topic: report.topic,
                            htmltext: report.htmltext,
                            day: report.day,
                            month: report.month,
                            year: report.year
                        });
                    });
                    dataSet.sort((a,b) => b.date - a.date);

                    setReportsData(dataSet)

                    let currentSlug = dataSet[0].slug
                    setReportSlug(currentSlug)

                    let currentReport = dataSet.find(obj => {
                        return obj.slug === currentSlug
                    })
                    setReportData(currentReport)

                    setOtherReports(dataSet.filter(rep => rep.slug !== currentSlug))
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                setLoadingReports(false);
            });
        }
    }, []);

    const setCurrentReport = (event, slug) => {
        //event.preventDefault();
        setReportSlug(slug);
        setOtherReports(reportsData.filter(rep => rep.slug !== slug))
    }

    useEffect(() => {
        if(reportsData.length > 0){
            let currentReport = reportsData.find(obj => {
                return obj.slug === reportSlug
            })
            setReportData(currentReport)
        }

    }, [reportSlug]);

  const page = (
      <>
          <ProductBanner
              bannerContainerClassName="bannerReporte"
              bannerTrasparecy="bannerMask"
              productTitle="Reportes"
              bannerImage={Banner}
              bannerImageClassName="ReportBannerImage"
              textContainerClassName="textContainer"
              bannerText={false}
              bannerTextClassName="bannerTextInversiones"
              bannerImageMobile={Banner}
              mobile={false}
          />
          <section className="fullwidth">
              <Container>
                  <Row className="pb-5">
                      <DateSelect
                          options={options}
                          selectName={"report-select"}
                          controller={setType}
                          className="report-select"
                          selected={'daily'}
                      />
                  </Row>
              </Container>

              <div className="report-abstract bg-lt-grey">
                  <Container>
                      <Row className="py-5">
                          <Col xs={12} md={9} className="d-flex align-items-center" >
                              <p className="base-text m-0 py-4">
                                  Resumen diario de los eventos más relevantes del mercado. Realizado por el Equipo de Estrategia y Research de Balanz.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </div>
              <Container id="reportSection">
                  {(loadingReports) ? (
                      <Loading customClass="loading-reports position-relative " />
                  ) : (
                      (reportData.day)?
                          (
                              <>
                              <Report
                                  date={`${reportData.day} <span class='smaller'> ${reportData.month} ${reportData.year} </span>`}
                                  topic={reportData.topic}
                                  text={reportData.htmltext}
                              ></Report>
                              <Row className="py-3">
                                  <Button variant="secondary download-report" onClick={() => { window.open(`${reportData.link}`, "_blank") }} text= "Reporte completo"/>
                              </Row>
                              </>
                          ):(
                              <p className="base-text py-5">No hay reportes para mostrar</p>
                          )

                  )}
              </Container>
          </section>
          <section className="fullwidth last-reports">
              <Container>
                  <Row>
                      <div className="last-reports-container p-5 bg-lt-grey base-text">

                          { otherReports.length > 0 &&
                          otherReports.map((item, key) => {
                              return (
                                  <li key={key}>
                                      <a href="#reportSection" onClick={ (event) => {setCurrentReport(event,`${item.slug}` ) }} >
                                          <span className="fecha">{item.day} {item.month} {item.year}</span>
                                          {item.topic}
                                      </a>
                                  </li>
                              );
                          })}
                      </div>
                  </Row>
              </Container>
          </section>
          <section className="fullwidth suscribe">
              <Container>
                  <Row className="d-flex justify-content-center">
                      <Col xs="12" md="11">
                          <NewsLetter
                              Text="¿Querés recibir nuestro
                              Daily? <br />
                              Si te interesa, registrá tu mail."
                              ClassNameDisplay="section-content"
                              ClassNameInput="custom-email-input"
                              ButtonStyle="secondary"
                              ButtonText="Enviar"
                              IDCampaignAction={1059}
                          />
                      </Col>
                  </Row>
              </Container>
          </section>
      </>
  );

  return (
    <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"reportes-daily"}></Layout>
    </main>
  );
};

// Step 3: Export your component
export default ReportPageDaily;
